<template>
  <form
    class="form form--auth-sms-code"
    v-if="isFormEnabled"
    v-on:hide-errors="hideErrors"
  >
    <slot></slot>
    <slot name="enter-phone" v-if="step == 'phone'"></slot>
    <slot name="enter-code" v-if="step == 'code'"></slot>
    <div class="alert alert--danger" v-if="error">
      <slot name="auth-error">
        <div v-html="error"></div>
      </slot>
    </div>
    <div class="step" v-if="step == 'phone'">
      <p>{{ textEnterPhoneNumber }}</p>
      <div class="step__fields">
        <div class="form-group">
          <masked-input
            class="form-field form-field--large js-phone"
            :placeholder="phonePlaceholder"
            type="tel"
            v-model="credentials.phone"
            :mask="phoneMask"
          ></masked-input>
        </div>
        <button
          class="button button--rounded button--large"
          @click.prevent="getCode()"
          :disabled="sending"
        >
          Получить&nbsp;код&nbsp;&rarr;
        </button>
      </div>
    </div>
    <div class="step" v-if="step == 'code'">
      <p>{{ textEnterCode }}</p>
      <div class="step__fields">
        <div class="form-group">
          <input
            type="tel"
            ref="code"
            pattern="\d*"
            class="form-field form-field--large"
            placeholder="Код"
            v-model="credentials.code"
            maxlength="6"
          />
        </div>
        <button
          class="button button--rounded button--large"
          @click.prevent="submit()"
          :disabled="sending"
        >
          Войти
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import Vue from "vue";
import auth from "../api/auth-sms-code";
import MaskedInput from "vue-text-mask";
import appError from "base/modules/app-error";
import utils from "base/modules/utils";
import { phoneMask, examplePhone } from "base/modules/constants";

Vue.component("masked-input", MaskedInput);

export default {
  props: {
    textEnterPhoneNumber: {
      type: String,
      default: "Введите ваш номер телефона"
    },
    textEnterCode: {
      type: String,
      default: "Введите код из SMS"
    },
    isFormEnabled: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      step: "phone",
      credentials: {
        phone: "",
        code: ""
      },
      sending: false,
      error: ""
    };
  },
  computed: {
    region() {
      return this.$store.getters["main/region"];
    },
    phoneMask() {
      return phoneMask[this.region];
    },
    phonePlaceholder() {
      return examplePhone[this.region].substring(
        0,
        examplePhone[this.region].indexOf(" ")
      );
    }
  },
  methods: {
    getCode() {
      const credentials = {
        phone: this.credentials.phone.replace(/[^0-9.]/g, "")
      };

      this.error = "";

      if (utils.validatePhone(credentials.phone)) {
        Vue.$logger.info(
          "AuthorizationSmsCode | auth.getCode | credentials: ",
          credentials
        );

        this.sending = true;

        auth
          .getCode(this.$store, credentials)
          .then(response => {
            this.sending = false;
            this.step = "code";
          })
          .catch(error => {
            this.sending = false;

            this.$emit("hide-errors");
            this.error = appError
              .getMessage(error.message)
              .replace("PHONE_EXAMPLE", examplePhone[this.region]);

            Vue.$logger.debug(
              "AuthorizationSmsCode | auth.getCode | ошибка: ",
              error
            );
          });
      } else {
        this.$emit("hide-errors");
        this.error = appError
          .getMessage("wrong_phone_format")
          .replace("PHONE_EXAMPLE", examplePhone[this.region]);
      }
    },
    submit() {
      const credentials = {
        phone: this.credentials.phone.replace(/[^0-9.]/g, ""),
        code: this.credentials.code
      };

      this.error = "";

      if (!utils.validatePhone(credentials.phone)) {
        this.$emit("hide-errors");
        this.error = "Неверный номер телефона";
      } else if (credentials.code.length === 0) {
        this.$emit("hide-errors");
        this.error = "Введите код";
      } else {
        Vue.$logger.info(
          "AuthorizationSmsCode | auth.login | credentials: ",
          credentials
        );

        this.sending = true;

        auth
          .login(this.$store, credentials)
          .then(response => {
            this.sending = false;

            const data = response.data;
            let role;

            try {
              const payload = JSON.parse(
                data.token.split(".").map(part => {
                  return utils.decodeBase64(part);
                })[1]
              );

              role = payload.role;
            } catch (error) {
              role = "user";
            }

            this.$store.commit("user/authenticate");

            this.$store
              .dispatch("user/login", { data, role })
              .then(response => {
                Vue.$logger.debug("AuthorizationSmsCode | authorized");

                this.$router.push("/cabinet").catch(() => {});
              });
          })
          .catch(error => {
            this.sending = false;

            this.$emit("hide-errors");
            this.error = appError.getMessage(error.message);

            Vue.$logger.debug(
              "AuthorizationSmsCode | auth.login | ошибка: ",
              error
            );
          });
      }
    },
    checkFocus() {
      if (this.step === "phone") {
        document.querySelector(".js-phone").focus();
      } else if (this.step === "code") {
        this.$refs.code.focus();
      }
    },
    hideErrors() {
      this.error = "";
    },
    afterMounted() {
      this.checkFocus();
    }
  },
  updated() {
    this.checkFocus();
  }
};
</script>

<style lang="css" scoped>
.form {
  margin-bottom: 20px;
}

.step__fields .form-group {
  margin-bottom: 10px;
}

.form-field {
  width: 100%;
}

@media (min-width: 480px) {
  .step__fields {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
  }

  .step__fields .form-group {
    margin-right: 10px;
    margin-bottom: 0;
  }
}
</style>
